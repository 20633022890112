const regexCompoId = /compo-/;

function initSimpleProductData(placement, componentInstanceId) {
  const simpleProductElements = getProducts(placement.sheet_id)
  if (!simpleProductElements?.length) return [];
  const productsFromPlacement = [];

  simpleProductElements.forEach((simpleProduct) => {
    simpleProduct.id = simpleProduct.id + '-' + componentInstanceId;
    while (simpleProduct.lastElementChild) {
      simpleProduct.removeChild(simpleProduct.lastElementChild);
    }

    const compoID = findCompoId(simpleProduct);
    const compoProductsData = placement.compositions.find((compo) => compo.id === compoID);
    if (compoProductsData) {
      const prodId = simpleProduct.dataset.name;
      const fullDataProduct = compoProductsData.artworks
      .flatMap(artwork => artwork.map(art => art?.artwork))
      .find((prod) => prod?.name === prodId);

      if (fullDataProduct) {
        const site_manager= JSON.stringify({entity: "artwork", id: fullDataProduct.id });
        const dataProduct = fullDataProduct.settings?.data;
        if (dataProduct) {
          dataProduct._is_available = true;
          const product = {
            id: simpleProduct.id,
            name: prodId,
            data: dataProduct,
            uuid: simpleProduct.id + componentInstanceId,
            site_manager: site_manager
          };

          if (!productsFromPlacement.some((prod) => prod.id === product.id)) {
            productsFromPlacement.push(product);
          }
        }
      }
    }
  });

  return productsFromPlacement;
}

function getProducts(sheetId) {
  const sheetContainer = document.querySelector('#sheet-' + sheetId);
  if(!sheetContainer) {
    return false;
  }
  const simpleProductElements = sheetContainer.querySelectorAll(
    '.artwork.product_store, .artwork.simple_product'
  );

  return simpleProductElements;
};

function findCompoId(product){
  let parentElement = product.parentElement;

  while (parentElement) {
      if (parentElement.id && regexCompoId.test(parentElement.id)) {
          return parentElement.id.replace(regexCompoId, '');
      }

      parentElement = parentElement.parentElement;
  }

  return null;
}

module.exports = {
  initSimpleProductData,
  getProducts,
}
