const BuyLookPlacementMapper = require('CommonUtils/mappers/buyLookPlacement.mapper');
const UtilsAnalytics = require('CommonUtils/analytics');

function loadBuyLookData (placement, componentInstanceId, instance) {
  try {
    if (!Array.isArray(placement?.compositions)) {
      return [];
    }
    const buyLookData = BuyLookPlacementMapper.getTotalLookData(placement);
    if (buyLookData.length) {
      transformBuyLookShowMethod(componentInstanceId, placement, instance);
      return buyLookData;
    }
  } catch (e) {
    console.log(e);
    return [];
  }
}

const transformBuyLookShowMethod = (componentInstanceId, placement, instance) => {
  // Usado para modificar la función a la que llama el enlace.
  // Se insertará la key del objeto en window que enlaza la función con este componente.  

  const totalLookElementsLinks = getLooksLinks(placement.sheet_id);
  totalLookElementsLinks.forEach((look) => {        
    look.href = look.href.replace('javascript:void(geci.vue.placements.showBuyLook', 'javascript:void(geci.vue.placements.showBuyLook'+`['${componentInstanceId}']`);
  });
  setWindowBuyLookMethod(componentInstanceId, instance);
}

function getLooksLinks(sheetId) {
  const sheetContainer = document.querySelector('#sheet-' + sheetId);
  if(!sheetContainer) {
    return false;
  }
  const totalLookElementsLinks = sheetContainer.querySelectorAll('.artwork.total_look ._href_js');

  return totalLookElementsLinks;
};

const setWindowBuyLookMethod = (componentInstanceId, instance) => {

  if (!window.geci) window.geci = {};
  if (!window.geci.vue) window.geci.vue = {};
  if (!window.geci.vue.placements) window.geci.vue.placements = {};
  if (!window.geci.vue.placements.showBuyLook) window.geci.vue.placements.showBuyLook = {};

  window.geci.vue.placements.showBuyLook[componentInstanceId] = function (id) {
    const data = instance.proxy.buyLookData.filter((item) => item.id === id)[0] || null;
    if (data) {
      const promoData = {
        id: data.id,
        name: data.name,
        pos: instance.proxy.placement.placement_name,
        creative: data.type,
      };
      UtilsAnalytics.sendPromoClickEvent(promoData);
    }
    instance.proxy.activeBuyLookId = id;
    instance.refs.buyLookModal.showModal();
  };
}

module.exports = {
  loadBuyLookData,
}