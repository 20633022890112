import { useStore } from 'vuex';
import uuidv4 from 'uuid/v4';

const useUUID = () => {
  const store = useStore();
  const getSequentialUuid = () => {
    store.commit('uuid/getSequentialUuid');
    const newUUID = store.getters['uuid/getCurrentSeed'];
    return newUUID;
  };
  const getRandomUuid = () => {
    return uuidv4();
  }

  return {
    getSequentialUuid,
    getRandomUuid,
  }
}

export default useUUID;