const addScriptFromUrl = (url, priority = 'auto', id, callback) => {
  if (id && _existScriptId(id)) return;
  const scriptElement = document.createElement('script');
  if (callback) scriptElement.addEventListener('load', callback);
  scriptElement.setAttribute('src', url);
  if (id) scriptElement.setAttribute('id', id);
  scriptElement.setAttribute('fetchpriority', priority);
  document.body.appendChild(scriptElement);
};

const _existScriptId = (id) => {
  return !!document.getElementById(id);
};

const addScriptWithDataset = (src, dataAttributes, container) => {
  if (!container) container = document.body;
  const scriptElement = document.createElement('script');
  if (src) {
    scriptElement.setAttribute('src', src);
  }
  const keys = Object.keys(dataAttributes);
  keys.forEach(function (item) {
    if (dataAttributes[item] !== '') {
      scriptElement.dataset[item] = dataAttributes[item].trim();
    }
  });
  container.appendChild(scriptElement);
};

module.exports = {
  addScriptFromUrl,
  addScriptWithDataset,
};
