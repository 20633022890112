const UtilsSegments = require('CommonUtils/siteManager/segments.js');
const UtilsVideo = require('CommonUtils/siteManager/video.js');
const UtilsProduct = require('CommonUtils/siteManager/product.js');
const UtilsTotalLook = require('CommonUtils/siteManager/total_look.js');
const UtilsRetailMedia = require('CommonUtils/siteManager/retail_media.js');
const newVueArtworks = ['multipurpose_vue', 'separator_vue', 'button_vue', 'showcase', 'image_vue'];

function hasPlacementHtml(placements, types) {
  if (!placements || typeof placements !== 'object') return false;

  if (Array.isArray(types)) {
    for (const type of types) {
      if (placements[type] && placements[type].html) {
        return true;
      }
    }
  } else if (typeof types === 'string') {
    if (placements[types] && placements[types].html) {
      return true;
    }
  }

  return false;
}

function countPlpTopPlacements(placements) {
  let count = 0;
  if (placements.header) count++;
  if (placements.hero) count++;
  if (placements.top_page) count++;

  return count;
}

function getProductRowInProductListWithPlacements(
  productIndex,
  currentPage,
  itemsPerPage,
  itemsPerRow,
  paginatedPlacements,
) {
  const itemsPerRowDesktop = itemsPerRow;
  const itemsPerRowMobile = itemsPerRow / 2;
  const rows = Object.entries(paginatedPlacements)
    .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
    .filter((item) => parseInt(item[0]) <= currentPage)
    .reduce(
      (prevPage, placements) => {
        const [page, pagePlacements] = placements;
        let productRelativePosition = itemsPerPage - 1;
        let notTopFullWidthPlacements = 0;
        if (parseInt(page) == currentPage) {
          productRelativePosition = productIndex % itemsPerPage;
        } else {
          if (pagePlacements.after_first_row) notTopFullWidthPlacements++;
          if (pagePlacements.after_second_row) notTopFullWidthPlacements++;
          if (pagePlacements.mid_page) notTopFullWidthPlacements++;
        }
        const result = {
          rowsDesktop: prevPage.rowsDesktop,
          lastRowEmptyBlocksDesktop: 0,
          rowsMobile: prevPage.rowsMobile,
          lastRowEmptyBlocksMobile: 0,
        };

        const topPlacements = countPlpTopPlacements(pagePlacements);
        if (topPlacements) {
          prevPage.lastRowEmptyBlocksDesktop = 0;
          result.rowsDesktop += 1;
          prevPage.lastRowEmptyBlocksMobile = 0;
          result.rowsMobile += 1;
        }

        const productPreviews = Object.keys(pagePlacements)
          .filter((item) => /^product_preview/.test(item))
          .map((item) => {
            const idx = item.match(/^product_preview\[(\d+)\]$/);
            if (Array.isArray(idx) && idx.length == 2) return idx[1];
            if (/^product_preview$/.test(item)) return 0;
          })
          .filter((item) => item <= productRelativePosition).length;

        const productBlocksDesktop = productRelativePosition + 1 + productPreviews - prevPage.lastRowEmptyBlocksDesktop;
        const productRowsDesktop = Math.ceil(productBlocksDesktop / itemsPerRowDesktop);
        const emptyProductBlocksDesktop =
          productBlocksDesktop % itemsPerRowDesktop
            ? itemsPerRowDesktop - (productBlocksDesktop % itemsPerRowDesktop)
            : 0;
        result.rowsDesktop += productRowsDesktop + notTopFullWidthPlacements;
        result.lastRowEmptyBlocksDesktop = emptyProductBlocksDesktop;

        const productBlocksMobile = productRelativePosition + 1 + productPreviews - prevPage.lastRowEmptyBlocksMobile;
        const productRowsMobile = Math.ceil(productBlocksMobile / itemsPerRowMobile);
        const emptyProductBlocksMobile =
          productBlocksMobile % itemsPerRowMobile ? itemsPerRowMobile - (productBlocksMobile % itemsPerRowMobile) : 0;
        result.rowsMobile += productRowsMobile + notTopFullWidthPlacements;
        result.lastRowEmptyBlocksMobile = emptyProductBlocksMobile;

        return result;
      },
      {
        rowsDesktop: 0,
        lastRowEmptyBlocksDesktop: 0,
        rowsMobile: 0,
        lastRowEmptyBlocksMobile: 0,
      },
    );
  return rows;
}

function getArtworksWithLinkThatOpensOnModal(placement) { 
  const sheetContainer = document.querySelector('#sheet-' + placement.sheet_id);
  if(!sheetContainer) {
    return false;
  }

  return sheetContainer.querySelectorAll('.artwork a.modal');  
}

function getArtworksWithLinks(placement) { 
  const sheetContainer = document.querySelector('#sheet-' + placement.sheet_id);
  if(!sheetContainer) {
    return false;
  }

  return sheetContainer.querySelectorAll('.artwork a');  
}

function hasArtworksWithMobileImage(placement) {
  return placement?.compositions?.find((compo) => {
    return compo.artworks?.every((artwork) => {
      return artwork === null || artwork?.every((art) => {
        return art === null || art?.artwork?.settings?.mobile_image;
      })
    });
  });
}

function getVueArtworks (placement) {
  const artworks = [];
  if( ! placement?.compositions) return artworks;
  
  placement.compositions.forEach( compo => {         
    const is_carousel = compo.is_carousel || compo.mobile_carousel;     
    compo?.artworks.forEach( (artwork, index) => {         
      if(!artwork) return;                
      const multi = artwork.find( art => art && newVueArtworks.includes(art.artwork.type));
      if(multi) {
        multi.artwork.in_placement = placement.placement_name;
        multi.artwork.is_carousel = is_carousel;        
        multi.artwork.layout = compo.layout?.[index];
        artworks.push(multi.artwork);          
      }
    })        
  });      
  return artworks;
};


module.exports = {
  hasPlacementHtml,
  countPlpTopPlacements,
  getProductRowInProductListWithPlacements,
  isHiddenByCDPSegments: UtilsSegments.isHiddenByCDPSegments,
  removeHiddenByCDPSegments: UtilsSegments.removeHiddenByCDPSegments,
  removeHiddenByCDPSegmentsAutofind: UtilsSegments.removeHiddenByCDPSegmentsAutofind,
  handleMrcaSegmentsCompositions: UtilsSegments.handleMrcaSegmentsCompositions,
  handleFunctionalSegmentsCompositions: UtilsSegments.handleFunctionalSegmentsCompositions,
  loadCDPfunctions: UtilsSegments.loadCDPfunctions,
  loadVideoController: UtilsVideo.loadVideoController,
  loadVideoConfiguration: UtilsVideo.loadVideoConfiguration,
  initSimpleProductData: UtilsProduct.initSimpleProductData,
  getProducts: UtilsProduct.getProducts,
  loadBuyLookData: UtilsTotalLook.loadBuyLookData,
  getArtworksWithLinks,
  getArtworksWithLinkThatOpensOnModal,
  loadRetailMediaData: UtilsRetailMedia.loadRetailMediaData,
  hasArtworksWithMobileImage,
  getVueArtworks,
};
