const SELECTORS = {
  guest: '.js-wineclub-guest',
  softlogged: '.js-wineclub-softlogged',
  subscribed: '.js-wineclub-subscribed',
  subscribedWelcome: '.js-wineclub-subscribed-welcome',
  nonsubscribed: '.js-wineclub-nonsubscribed',
  landing: '.js-wineclub-landing',
  iframe: '#iframe-cdv'
};

const DATA_ATTRIBUTES = {
  redirectGuest: 'data-redirect-guest',
  redirectSoftlogged: 'data-redirect-softlogged',
  redirectSubscribed: 'data-redirect-subscribed',
  redirectSubscribedWelcome: 'data-redirect-subscribed-welcome',
  redirectNonsubscribed: 'data-redirect-nonsubscribed'
};

const isSoftLogged = (acr) => acr === '0';

const isValidPlacement = (placement) => placement?.html;

const isWineClub = (placement) => isValidPlacement(placement) && placement.html.includes('js-wineclub-landing');

const createWineClubLanding = (placement) => {
  if (!isValidPlacement(placement)) {
    return;
  }
  const element = document.createElement('div');
  element.innerHTML = placement.html;
  return element;
};

const showLayoutIfExists = (wineClubLanding, className) => {
  if (!wineClubLanding || !(wineClubLanding instanceof HTMLElement) || !className) {
    return;
  }
  const layout = wineClubLanding.querySelector(className);
  if (!layout) {
    return;
  }
  resizeIframeIfExists(layout);
  layout.classList.remove('hidden');
  return wineClubLanding.outerHTML;
};

const getLayoutIfExists = (wineClubLanding, selector) => {
  if (!wineClubLanding || !(wineClubLanding instanceof HTMLElement) || !selector) {
    return;
  }
  return wineClubLanding.querySelector(selector);
};

const tryRedirect = (element, dataAttribute) => {
  const redirection = getRedirection(element, dataAttribute);
  if (!redirection) {
    return false;
  }
  window.location.replace(redirection);
  return true;
};

const getRedirection = (element, attribute) => {
  if (!element) {
    return;
  }
  const layout = getLayoutIfExists(element, SELECTORS.landing);
  return layout?.getAttribute(attribute);
};

const handleRedirectionOrLayout = (wineClubLanding, dataAttribute, selector) => {
  // Redirect the user if the specified data attribute exists
  if (tryRedirect(wineClubLanding, dataAttribute)) {
    return;
  }
  // If no redirection,display the specified layout
  return showLayoutIfExists(wineClubLanding, selector);
};

const resizeIframeIfExists = (layout) => {
  const iframe = layout.querySelector(SELECTORS.iframe);
  if (iframe) {
    iframe.height = document.body.scrollHeight + 'px';
  }
};

const handleGuestUser = (placement) => {
  const wineClubLanding = createWineClubLanding(placement);
  return handleRedirectionOrLayout(wineClubLanding, DATA_ATTRIBUTES.redirectGuest, SELECTORS.guest);

};

const handleSoftLoggedUser = (placement) => {
  const wineClubLanding = createWineClubLanding(placement);
  return handleRedirectionOrLayout(wineClubLanding, DATA_ATTRIBUTES.redirectSoftlogged, SELECTORS.softlogged);
};

const handleHardLoggedUser = (placement, subscribed, welcome) => {
  const wineClubLanding = createWineClubLanding(placement);

  if (!subscribed) {
    return handleRedirectionOrLayout(wineClubLanding, DATA_ATTRIBUTES.redirectNonsubscribed, SELECTORS.nonsubscribed);
  }
  // recently subscribed 
  if (welcome) {
    return handleRedirectionOrLayout(wineClubLanding, DATA_ATTRIBUTES.redirectSubscribedWelcome, SELECTORS.subscribedWelcome);
  }
  // subscribed
  return handleRedirectionOrLayout(wineClubLanding, DATA_ATTRIBUTES.redirectSubscribed, SELECTORS.subscribed);
};

module.exports = {
  handleGuestUser,
  handleHardLoggedUser,
  handleSoftLoggedUser,
  isSoftLogged,
  isWineClub
};
