<template>
  <div
    :id="placementId"
    :class="[containerClass]"
    :style="placementCustomStyle">
    <Component
      :is="vuePlacement"
      v-if="vuePlacement"
      :placement="placement" />
    <div
      v-else-if="placement.customClickEventName"
      class="custom_click_placement"
      @click.stop="placementCustomClicked"
      v-html="placement.html" />
    <div
      v-else
      v-html="placement.html" />

    <BuyLookModal
      v-if="buyLookData?.length"
      ref="buyLookModal"
      :look="activeBuyLook"
      @isclosed="handleBuyLookClose" />
    <template v-if="isMounted">
      <template v-if="isCdpCarousel && isLogged">
        <Teleport
          :to="'#js-custom-carousel'"
          :disabled="!isMounted">
          <CarouselCDP
            v-show="showCarousel"
            :max-products="maxProducts"
            :category-id="categoryId"
            :seg-eci-lover="segEciLover"
            @hide-carousel="showCarousel = false"
            @show-carousel="showCarousel = true" />
        </Teleport>
      </template>
      <template
        v-for="(product, idx) in productsFromPlacement"
        :key="product.id">
        <Teleport
          :to="'#' + product.id"
          :disabled="!isMounted">
          <div class="artwork-toolbar disabled">
            <span :sitemanager-data="product.site_manager"></span>
          </div>
          <ProductPreview
            :key="`product_preview_${product.id}-${idx}-${uuid}`"
            :force-show-brand="true"
            :is-product-store="true"
            :position="idx + 1"
            :product="product.data" />
        </Teleport>
      </template>

      <template
        v-for="(artwork) in vueArtworks"
        :key="artwork.id">
        <Teleport :to="'#artwork-' + artwork.id">          
          <Artwork
            :artwork="artwork"
            :type="artwork.type"
            @open-in-modal="openInModal(artwork.settings)" />
        </Teleport>
      </template>
      <template v-if="showLinkOnModal">
        <Teleport :to="'#Layout-modal-iframe'">
          <ModalWithIframe
            :iframeURL="linkOnModal"
            :showFooter="false"
            spanHeaderLayer
            @closeModal="closeModal" />
        </Teleport>
      </template>
      <template v-if="productRetailMediaData && productRetailMediaData.length">
        <template
          v-for="product_sponsor in productRetailMediaData"
          :key="`product_sponsor-${placement.trigger}-${product_sponsor.composition_id}-${product_sponsor.id}`">
          <Teleport
            :to="`#product_sponsor-${placement.trigger}-${product_sponsor.composition_id}-${product_sponsor.id}`">
            <RetailMediaProduct
              :id="`product_sponsor-${placement.trigger}-${product_sponsor.composition_id}-${product_sponsor.id}`"
              :trigger="placement.trigger"
              :position="index + 1"
              :placement="product_sponsor"
              :product="product_sponsor" />
          </Teleport>
        </template>
      </template>
      <template v-if="displayRetailMediaData && displayRetailMediaData.length">
        <template
          v-for="display_sponsor in displayRetailMediaData"
          :key="`display_sponsor-${placement.trigger}-${display_sponsor.composition_id}-${display_sponsor.id}`">
          <Teleport
            :to="`#display_sponsor-${placement.trigger}-${display_sponsor.composition_id}-${display_sponsor.id}`">
            <RetailMediaDisplay
              :id="`display_sponsor-${placement.trigger}-${display_sponsor.composition_id}-${display_sponsor.id}`"
              :placement="display_sponsor"
              :slot-desktop="display_sponsor.settings.slot_desktop"
              :slot-mobile="display_sponsor.settings.slot_mobile" />
          </Teleport>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import StockUtils from 'CommonUtils/schemas/stock.js';
import UtilsPlacements from 'CommonUtils/placements.js';
import { mapAction, mapGetter } from 'CommonUtils/store/state.js';
import CartUtils from 'CommonUtils/schemas/cart';
import UtilExposer from 'CommonUtils/exposer.js';
import UtilsAnalytics from 'CommonUtils/analytics.js';
import UtilsURL from 'CommonUtils/url';
import UtilsWineClub from 'CommonUtils/wineClubUtils';
import Artwork from 'CommonComponents/Sheets/Artwork.vue';
import useUUID from 'CommonComposables/useUUID';

const vueSheets = {
  parallax: 'Parallax',
  wineClub: 'WineClub',
};

export default {
  name: 'Render',
  components: {
    BuyLookModal: defineAsyncComponent(() => import('CommonComponents/Sheets/BuyLook/BuyLookModal.vue')),
    ProductPreview: defineAsyncComponent(() => import('Components/ProductPreview/ProductPreview.vue')),
    Parallax: defineAsyncComponent(() => import('CommonComponents/Sheets/Parallax/Parallax.vue')),
    CarouselCDP: defineAsyncComponent(() => import('CommonComponents/Carousel/CarouselCDP.vue')),
    ModalWithIframe: defineAsyncComponent(() => import('CommonComponents/Iframe/ModalWithIframe.vue')),
    RetailMediaProduct: defineAsyncComponent(() => import('Components/RetailMedia/RetailMediaProduct.vue')),
    RetailMediaDisplay: defineAsyncComponent(() => import('Components/RetailMedia/RetailMediaDisplay.vue')),
    WineClub: defineAsyncComponent(() => import('Components/WineClub/WineClub.vue')),    
    Artwork,
  },
  props: {
    placement: {
      type: Object,
      required: true,
    },
    containerClass: {
      type: [String, null],
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    type: {
      type: [String, null],
      default: null,
      required: false,
    },
    inModal: {
      type: [Boolean, String],
      default: false,
    },
  },
  emits: ['buyLookModalClosed', 'externalLinkClicked', 'placementCustomClicked'],
  setup(props, { emit }) {
    const productionUrl = 'https://www.elcorteingles.es';
    const { getRandomUuid } = useUUID();
    const placementId = `place-${props.placement.trigger}`;
    const uuid = getRandomUuid();
    const buyLookData = ref([]);
    const placementCustomStyle = ref('');
    const activeBuyLookId = ref(null);
    const productsFromPlacement = ref([]);
    const vueArtworks = ref([]);
    const send_products = ref([]);
    const isMounted = ref(false);
    const showCarousel = ref(false);
    const showLinkOnModal = ref(false);
    const linkOnModal = ref(undefined);

    const baseUrl = mapGetter('page/getBaseUrl');
    const isLogged = mapGetter('cart/isLogged');
    const isWineClub = UtilsWineClub.isWineClub(props.placement);
    const cartStatus = mapGetter('cart/getCartCallStatus');
    const hasShippingPrimeActivated = mapGetter('cart/getShippingPrimeActivated');
    const isProd = mapGetter('page/isProd');

    const loyaltyData = mapGetter('cart/getLoyaltyData');

    const setHasParallaxSheet = mapAction('page', 'setHasParallaxSheet');
    const getStock = mapAction('page', 'getStock');
    const customization = mapGetter('page/getLayoutOptions');
    const cdpSegmentAutoHideEnabled = customization?.cdp_segments_autohide_enabled || true;

    const maxProducts = ref(null);
    const products = ref(null);
    const categoryId = ref(null);
    const eciLover = ref(null);
    const segEciLover = ref(null);
    const urlsThatNeedVuestore = ['/tarjeta-regalo-fisica/empresas/'];

    
    const displayRetailMediaData = computed(()=> {
      return UtilsPlacements.loadRetailMediaData(props.placement, 'display');
    });
    const productRetailMediaData = computed(()=> {
      return UtilsPlacements.loadRetailMediaData(props.placement, 'product_sponsor');
    });

    const vuePlacement = computed(() => {
      const vueSheet = getVueSheets();
      if (vueSheet === 'Parallax') {
        setHasParallaxSheet(true);
      }
      return vueSheet;
    });

    const activeBuyLook = computed(() => {
      if (!activeBuyLookId.value || !buyLookData.value.length) {
        return null;
      }
      return buyLookData.value.filter((item) => item.id === activeBuyLookId.value)[0] || null;
    });

    const hasProducts = computed(() => {
      return UtilsPlacements.getProducts(props.placement.id)?.length > 0;
    });

    const handleBuyLookClose = () => {
      activeBuyLookId.value = null;
      emit('buyLookModalClosed');
    };
    
    const openInModal = (settings) => {
      showLinkOnModal.value = true;
      const params = { modal: 1 };
      const url = settings.url || settings.link || settings.button_url;
      linkOnModal.value = UtilsURL.addNewParamToQuery(url, params);                
    };

    const getProductsStock = () => {
      if (!productsFromPlacement.value.length) return;
      const products = productsFromPlacement.value.map((product) => {
        return product.data;
      });
      const ids = StockUtils.getProductIdsToCheckStock(products);
      if (ids.length) {
        getStock({ ids, type: 'product' })
          .then((response) => {
            if (!response.success || !response.data) {
              console.error('[ProductList] Error fetching stock');
              return { products: {} };
            }
            StockUtils.setProductsStock(response.data, products);
            handlePromotionalProductsStock(response.data, products);
          })
          .catch((error) => {
            console.error(error);
          });

        const products_analytics = productsFromPlacement.value.map((product) => {
          const product_datalayer = product.data._datalayer[0].product;
          product_datalayer.hierarchy = product.data.hierarchy;
          product_datalayer.category = product.data.categories.map((cat) => cat.name);
          const product_data = toRaw(product_datalayer);
          product.data.product_store_datalayer = product_data;
          return product_data;
        });
        return products_analytics;
      }
    };

    const handlePromotionalProductsStock = (productsStock, products) => {
      const giftReferences = StockUtils.getAllGiftReferencesFromStock(productsStock);
      if (giftReferences.length) {
        getStock({ ids: giftReferences, type: 'gifts' }).then((giftStock) => {
          StockUtils.setProductPromosWithStock(products, productsStock, giftStock.data);
        });
      }
    };

    watch(cartStatus, (status) => {
      if (status !== CartUtils.STATUS.LOADED) return;
      UtilsPlacements.handleFunctionalSegmentsCompositions(
        props.placement.compositions,
        isLogged.value,
        hasShippingPrimeActivated.value
      );
    });
    watch(loyaltyData, (mrca) => {
      if (mrca === null) return;
      UtilsPlacements.handleMrcaSegmentsCompositions(props.placement.compositions, mrca, isLogged.value);
    });

    const initPlacement = () => {
      UtilsPlacements.loadVideoController();
      buyLookData.value = UtilsPlacements.loadBuyLookData(props.placement, uuid, getCurrentInstance());
      productsFromPlacement.value = UtilsPlacements.initSimpleProductData(props.placement, uuid);
      vueArtworks.value = UtilsPlacements.getVueArtworks(props.placement);
      if (productsFromPlacement.value.length) {
        send_products.value = getProductsStock(productsFromPlacement.value);
      }
      if (cdpSegmentAutoHideEnabled) {
        UtilsPlacements.removeHiddenByCDPSegments(props.placement.compositions);
      }
      if(productRetailMediaData.value.length || displayRetailMediaData.value.length ) {
        placementCustomStyle.value = 'height: 100%; width: 100%;';
      }
      if (cartStatus.value === CartUtils.STATUS.LOADED) {
        UtilsPlacements.handleFunctionalSegmentsCompositions(
          props.placement.compositions,
          isLogged.value,
          hasShippingPrimeActivated.value
        );
      }
      if (loyaltyData.value !== null) {
        UtilsPlacements.handleMrcaSegmentsCompositions(props.placement.compositions, loyaltyData.value, isLogged.value);
      }
      const artworkWithLinkThatOpensOnModal = UtilsPlacements.getArtworksWithLinkThatOpensOnModal(props.placement);
      if (artworkWithLinkThatOpensOnModal?.length) {
        initializeArtworksThatOpenInModal(artworkWithLinkThatOpensOnModal);
      }
      const artworksWithLink = UtilsPlacements.getArtworksWithLinks(props.placement);
      if (artworksWithLink?.length) {
        artworksWithLink.forEach(artworkWithLink => {
          artworkWithLink.href = addVuestoreParamIfNecesary(artworkWithLink.href);
          artworkWithLink.addEventListener('click', () => {
            emit('externalLinkClicked', placementId);
          });
        });
      }
      isMounted.value = true;
    };

    const addVuestoreParamIfNecesary = (url) => {
      const urlPathName = UtilsURL.getURLWithoutDomainAndParams(url);
      if (urlsThatNeedVuestore.includes(urlPathName)) {
        return UtilsURL.addNewParamToQuery(url, { vuestore: 1 });
      }
      return url;
    };

    const isCdpCarousel = computed(() => {
      if (!isMounted.value) return false;
      sendProductAnalytics();
      return props.placement?.html?.indexOf('js-custom-carousel') !== -1;
    });

    const initializeArtworksThatOpenInModal = (artworskWithLinkThatOpenInModal) => {
      artworskWithLinkThatOpenInModal.forEach((artwork) => {
        const artworkLink = document.getElementById(artwork.id);
        artworkLink?.addEventListener('click', (e) => {
          e.preventDefault();
          showLinkOnModal.value = true;
          const params = { modal: 1 };
          linkOnModal.value = UtilsURL.addNewParamToQuery(artwork.link ?? artwork.href, params);          
          if (!isProd.value) {
            linkOnModal.value = linkOnModal.value.replace(productionUrl, baseUrl.value);
          }
        });
      });
    };

    const sendProductAnalytics = () => {
      let products = toRaw(send_products.value);
      if (!products.length) return;
      const hiddenCompos = document.querySelectorAll(
        '.composition.hidden .composition-container, .composition-container.hidden'
      );
      const hiddenIds = [];
      hiddenCompos.forEach((compo) => {
        if (compo.id && /compo-/.test(compo.id)) {
          hiddenIds.push(compo.id.replace(/compo-/, ''));
        }
      });

      if (hiddenIds.length) {
        const notSendProducts = getProductsHidden(props.placement.compositions, hiddenIds);
        products = products.filter(
          (product) => !notSendProducts.includes(product.id) && !notSendProducts.includes(product.code_a)
        );
      }

      UtilsAnalytics.sendProductModulesPushInitial(products);
    };

    const getProductsHidden = (compositions, hiddenCompos) => {
      const products = [];
      compositions.forEach((compo) => {
        if (hiddenCompos.includes(compo.id) && compo.hasProductStore) {
          if (compo.artworks && compo.artworks.length) {
            compo.artworks.map((art) => {
              art.map((elem) => {
                if (elem.artwork.type === 'product_store') products.push(elem.artwork.settings.product_id);
              });
            });
          }
        }
      });
      return products;
    };

    const displayUserCdpProducts = (max_products, category_id, seg_eci_lover) => {
      maxProducts.value = max_products;
      segEciLover.value = seg_eci_lover;
      categoryId.value = category_id;
    };

    const setCdpCarouselFunction = () => {
      if (isCdpCarousel.value) {
        UtilExposer(['placements', 'compositions', 'cdp'], 'displayCdpProductsCarousel', displayUserCdpProducts);
      }
    };

    const getVueSheets = () => {
      if (isWineClub) {
        return vueSheets['wineClub'];
      }
      return vueSheets[props.placement.sheet_type];
    };

    const closeModal = () => {
      showLinkOnModal.value = false;
    };

    const placementCustomClicked = () => {
      emit('placementCustomClicked', props.placement.customClickEventName);
    }

    onMounted(() => {
      if(!props.inModal) {
        initPlacement();
        setCdpCarouselFunction();      
      }
      else {
        UtilsPlacements.loadVideoController(props.inModal);
      }
    });

    return {
      placementId,
      uuid,
      buyLookData,
      activeBuyLookId,
      productsFromPlacement,
      vueArtworks,
      isMounted,
      isLogged,
      cartStatus,
      hasShippingPrimeActivated,
      loyaltyData,
      vuePlacement,
      activeBuyLook,
      hasProducts,
      getProductsStock,
      handleBuyLookClose,
      isCdpCarousel,
      maxProducts,
      products,
      categoryId,
      eciLover,
      segEciLover,
      showCarousel,
      showLinkOnModal,
      linkOnModal,
      closeModal,
      placementCustomStyle,
      displayRetailMediaData,
      productRetailMediaData,
      placementCustomClicked,
      openInModal,
    };
  },
};
</script>

<style lang="less" scoped="true">
  .custom_click_placement {
    cursor: pointer;
  }
</style>
