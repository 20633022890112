


function loadRetailMediaData (placement, artwork_type) {
  try {
    if (!Array.isArray(placement?.compositions)) {
      return [];
    }
    const retailMediaData = getRetailMediaData(placement, artwork_type);
    return retailMediaData;
  } catch (e) {
    console.error(artwork_type, placement, e);
    return [];
  }
}


function getRetailMediaData (placement, artwork_type) {
  if(!placement) return [];
  const retailMediaData = [];
  let compositions = Array.isArray(placement.compositions) ? 
    placement.compositions : [ placement.compositions ]
  compositions = compositions
    .filter(
      c => !!c && c.artworks && Array.isArray(c.artworks)
    );
  for (const composition of compositions) {
    for (const artworks of composition.artworks.filter(
      x => !!x && Array.isArray(x)
    )) {
      for (const artworkItem of artworks.filter(x => !!x && x.artwork)) {
        if (artworkItem.artwork.type === artwork_type) {
          retailMediaData.push({
            composition_id: composition.id, 
            ...artworkItem.artwork 
          });
        }
      }
    }
  }
  return retailMediaData;
}

module.exports = {
  loadRetailMediaData
}