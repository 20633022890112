const { isEqual } = require('CommonUtils/operations/isEqual');
const { uniqWith } = require('CommonUtils/operations/uniqWith');

const buildSimplePromos = (domElementsToSearch, megadropPromos = null, skipMegadropPromos = false) => {
  let promos = [];

  const colSpan = Array.from(
    domElementsToSearch.querySelectorAll("span.dataholder[data-scope='promotion'][data-hasnav='true']"),
  ); // Recupera las promos navegables
  if (colSpan.length) {
    promos = colSpan
      .map(function (span, idx) {
        var oDiv =
          span.closest('div.image-overlay-out') ||
          span.closest('div.non-overlay-out') ||
          span.closest('div.image-overlay-in') ||
          span.closest('div.non-overlay-in') ||
          span.closest('div.composition-container');
        var processPromo = true;
        var data = JSON.parse(span.getAttribute('data-json'));
        if (skipMegadropPromos && megadropPromos && !megadropPromos.includes(data.id)) return;
        if (!skipMegadropPromos && megadropPromos && megadropPromos.includes(data.id)) return;
        var oDivDmp = span.closest('div.composition-container[data-dmp_segments]');
        if (oDivDmp) {
          //Es una promo DMP
          var dmpValue = oDivDmp.getAttribute('data-dmp_segments'); //Segmentos asignados, viene cadena separada por comas
          var dmpValues = dmpValue.split(',');

          var lsKeysDmp = ['kxelcorteinglessa_allsegs', 'kxelcorteinglessa_segs'];
          var dmpFound = false;
          lsKeysDmp.forEach(function (key) {
            var findValue = window.localStorage.getItem(key); //Segmentos disponibles
            if (!dmpFound && findValue) {
              dmpValues.forEach(function (dmpValueSeek) {
                if (findValue.indexOf(dmpValueSeek) !== -1) dmpFound = true;
              });
            }
          });
          processPromo = dmpFound;
        } else if (oDiv && window.getComputedStyle(oDiv).display === 'none') {
          processPromo = false; //Si el contenedor div que tiene class=image-overlay-out o non-overlay-out' y está oculto, no se manda a GTM
        }

        if (processPromo) {
          if (data) {
            var promoData = {
              id: data.id,
              name: data.name,
              pos: data.position,
              creative: data.creative,
            };

            var promoObject = span.nextElementSibling;
            if (promoObject) {
              attachPromoClickEvent(promoObject, promoData);
            }

            if( data.is_carousel) promoData = handleCarousel(span, promoData);

            if (data.creative === 'canvas' || data.creative === 'multipurpose') {
              promoData = handleCanvas(span, promoData);
            }

            return promoData;
          } else {
            console.warn('loadPromotions:: No se ha especificado data-json para la promocion[' + idx + ']');
          }
        }
      })
      .filter(function (promo) {
        return promo ? promo : undefined;
      });
  }
  return uniqWith(promos, isEqual);
};

const attachPromoClickEvent = (htmlObject, promoData) => {
  var payloadCLick = {
    event: 'promotion_click',
    promotion: promoData,
  };
  if (htmlObject.tagName.toLocaleLowerCase() !== 'a') {
    htmlObject = htmlObject.querySelector('a');
  }
  if (htmlObject && (htmlObject.getAttribute('href') !== null || htmlObject.getAttribute('href') !== '')) {
    htmlObject.addEventListener('click', function () {
      window.dataLayer.push(payloadCLick);
    });
  }
};

const handleCanvas = (span, tmp) => {
    var canvas = span.closest('.artwork.canvas');
    if (!canvas) return tmp;
    var display = 'none';
    // Control in mobile version
    if (canvas.classList.contains('small')) {
      var canvasDisplay = window.getComputedStyle(canvas).display;
      if (canvasDisplay !== 'none') {
        var txt_div = span.closest('div:not(.links-set)');
        display = window.getComputedStyle(txt_div).display;
      }
    } else display = window.getComputedStyle(canvas).display;
  
    if (display !== 'none') return tmp;
  }

const handleCarousel = function( span, tmp ) {
  const isMobile  = window.matchMedia('screen and (max-width: 996px)').matches;
  const wrapper   = span.closest('ul.carousel-artworks-wrapper');
  const bounding  = wrapper.getBoundingClientRect();
  const slide     = span.closest('li.artwork.artwork-multilayer');
  const rec       = slide.getBoundingClientRect();
  const artwork   = span.closest('div.artwork');
  const visible   = window.getComputedStyle(artwork).display;
  const diff      = Math.floor(bounding.right - rec.left);
  const diffOK    = (isMobile) ? 40 : 0;

  if( rec.left >= bounding.left && diff > diffOK && visible !== 'none' ){
    return tmp;
  }
}

module.exports = {
  buildSimplePromos,
  handleCanvas
};
