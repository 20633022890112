export function isHiddenByCDPSegments(placement) {
  const userSegsLikeString = window.localStorage.getItem('kxelcorteinglessa_segs');
  const placementCompositions = placement?.compositions;
  let compositions;
  
  if(!placementCompositions ){
    compositions = [];
  }else if(Array.isArray(placementCompositions)){
    compositions = placementCompositions.filter((element) => element !== undefined);
  }else{
    compositions = [];
  }
  const hasVisibleComposition = compositions.some((compo) => {
    return compo.cdp_segments?.length ?
      compo.cdp_segments.some((seg) => typeof userSegsLikeString === 'string' && userSegsLikeString.includes(seg)) :
      true;
  });
  return !hasVisibleComposition;
}

export function removeHiddenByCDPSegments (compositions) {
  let compositionsCDPSegments;  
  if(!compositions){
    compositionsCDPSegments = [];
  }else{
    compositionsCDPSegments = Array.isArray(compositions) ? compositions : [];
  }

  const userSegsLikeString = window.localStorage.getItem('kxelcorteinglessa_segs');
  if (!userSegsLikeString) {
    return;
  }
  const segmentsToShow = compositionsCDPSegments.filter(
    (compo) => compo.cdp_segments?.length && compo.cdp_segments.some((seg) => userSegsLikeString.includes(seg))
  );

  segmentsToShow.forEach((compo) => {
    document.getElementById('compo-' + compo.id)?.classList.remove('hidden');
  });
};

export function removeHiddenByCDPSegmentsAutofind (value) {
  if (value){
    window.localStorage.setItem('kxelcorteinglessa_segs', value);
  }
  const userSegsLikeString = window.localStorage.getItem('kxelcorteinglessa_segs');
  const compositions = Array.from(document.getElementsByClassName('composition-container'));
  if(!compositions || !compositions.length || !userSegsLikeString) {
    return;
  }
  compositions
    .filter((composition) => {
      if(!composition.getAttribute('data-cdp_segments') || !composition.getAttribute('data-cdp_segments').length) {
        return false;
      }
      const cdp_segments = composition.getAttribute('data-cdp_segments').split(',');
      return cdp_segments 
        && cdp_segments?.length 
        && cdp_segments.some((segment) => userSegsLikeString.includes(segment));
    }).forEach((composition) => {
      document.getElementById(composition.id).classList.remove('hidden');
    });
};

export function loadCDPfunctions (compositions) {
  removeHiddenByCDPSegments(compositions);
  removeHiddenByCDPSegmentsAutofind();
}

export function handleFunctionalSegmentsCompositions(compositions, isLogged, hasShippingPrimeActivated) {
  if(!Array.isArray(compositions)){
    compositions = [compositions];
  }
  compositions.filter( (compo) => compo.control_segments).forEach((composition) => {
    let segmentType = '';
    const control   = composition.segments.join(',');
    if(control.indexOf('flate_rate') !== -1) segmentType = 'flate_rate';
    if(control.indexOf('logged_') !== -1) segmentType = 'loging';

    switch (segmentType) {
    case 'flate_rate':
      handleFlateRateCompositions(composition, isLogged, hasShippingPrimeActivated);
      break;
    case 'loging':
      handleLoginSegments(composition, isLogged);
      break;
    default:
      break;
    }
  });
};
export function handleMrcaSegmentsCompositions(compositions, mrcaStorage, isLogged) {
  compositions.filter( (compo) => compo.control_segments).forEach((composition) => {
    const control   = composition.segments.join(',');
    if(control.indexOf('_colective_') !== -1 || control.indexOf('_collective_') !== -1) {
      handleMrcaSegments(composition, mrcaStorage, isLogged);
    }
  });
};

export function addCompositionClass(id, addClass) {
  const composition = document.getElementById('compo-' + id);
  if(addClass){
    composition.classList.add('hidden');
  } else {
    composition.classList.remove('hidden');
  }
};

/*
* si flate_rate es true y usuario logado tiene tarifa plana mostraremos las composiciones
* si flate_rate es true y usuario logado no tiene tarifa plana no mostraremos las composiciones
* si flate_rate es true y usuario no logado no mostraremos las composiciones
* si flate_rate es false y usuario logado no tiene la tarifa plana mostraremos las composiciones
* si flate_rate es false y usuario logado tiene la tarifa plana no mostraremos las composiciones
* si flate_rate es false y usuario no logado mostraremos las composiciones
*/
export function handleFlateRateCompositions(composition, isLogged, hasShippingPrimeActivated) {
  const withFlateRate = composition.segments.includes('flate_rate_true');
  if( withFlateRate && isLogged && hasShippingPrimeActivated ) addCompositionClass(composition.id, false);
  if( !withFlateRate && ((isLogged && !hasShippingPrimeActivated) || !isLogged) ) addCompositionClass(composition.id, false);
};

export function handleMrcaSegments(composition, mrcaStorage, isLogged) {
  if( !isLogged || !mrcaStorage) return;
  const userColectives = mrcaStorage.colectives;
  composition.segments.forEach((segment) => {
    if(segment.indexOf('_colective_') === -1 && segment.indexOf('_collective_') === -1 ) return;
    const colective   = segment.split('_colective_');
    const collective  = segment.split('_collective_');
    if( userColectives.includes(colective[0]) && colective[1] === 'true') addCompositionClass(composition.id, false);
    if( !userColectives.includes(colective[0]) && colective[1] === 'false') addCompositionClass(composition.id, false);
    if( userColectives.includes(collective[0]) && collective[1] === 'true') addCompositionClass(composition.id, false);
    if( !userColectives.includes(collective[0]) && collective[1] === 'false') addCompositionClass(composition.id, false);
  });
};
export function handleLoginSegments(composition, isLogged) {
  const forLogged   = composition.segments.includes('logged_true');
  const forUnlogged = composition.segments.includes('logged_false');

  if( isLogged && forLogged) addCompositionClass(composition.id, false);
  if( !isLogged && forUnlogged) addCompositionClass(composition.id, false);
};

export function removeByCDPSegments(elements) {
  const userSegsLikeString = window.localStorage.getItem('kxelcorteinglessa_segs');  
  
  const elementsToShow = elements.value.filter(
    (element) => {
      if(!element.cdp_segments?.length) return element;
      else if (element.cdp_segments?.length && element.cdp_segments.some((seg) => userSegsLikeString?.includes(seg))) return element;      
    }
  );
  
  return elementsToShow;
}; 