
function buildBuyLookDataByArtwork (artwork, parentId) {
  try {
    return {
      id: artwork.id,
      parent_id: parentId,
      name: artwork.name,
      type: artwork.type,
      mainImage: artwork.settings.image,
      mainImageTitle: artwork.settings.image_title,
      callToAction: artwork.settings.cta_text,
      analyticsName: artwork.analytics_name,
      products: artwork.settings.multi_product_id
    }
  } catch (err) {
    console.log('Error processing Buy The Look placement data: ', err);
    return null;
  }
}

function getProductPlainImage(skuImages) {
  let image = null;
  const images = skuImages?.all_images || null;
  if(Array.isArray(images) && images.length > 0) {
    image = images.find(image => image.is_plain)?.sources?.small || null;
    if(!image) image = images[0] || null;
    if(image.sources && image.sources.small) {
      return image.sources.small;
    }else if (image.default_source){
      return image.default_source;
    }
  } else {
    image = skuImages?.image?.sources?.small || null;
    if(!image) image = skuImages?.image?.default_source ||null;
  }
  return image;
}

function getTotalLookData (placement) {
  if(!placement) return [];
  const totalLookData = [];
  let compositions = Array.isArray(placement.compositions) ? 
    placement.compositions : [ placement.compositions ]
  compositions = compositions
    .filter(
      c => !!c && c.artworks && Array.isArray(c.artworks)
    );
  for (const composition of compositions) {
    for (const artworks of composition.artworks.filter(
      x => !!x && Array.isArray(x)
    )) {
      for (const artworkItem of artworks.filter(x => !!x && x.artwork)) {
        if (artworkItem.artwork.type === 'total_look') {
          const data = buildBuyLookDataByArtwork(artworkItem.artwork);
          if (data) {
            totalLookData.push(data);
          }
        }
      }
    }
  }
  return totalLookData;
}

module.exports = {
  getProductPlainImage,
  buildBuyLookDataByArtwork,
  getTotalLookData,
}